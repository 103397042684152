<template>
  <div
    v-if="values"
    class="bg-white border border-nColorStrokeGray rounded-xl md:px-4 md:pt-5 pb-4 obk-nha mb-4 md:mb-0"
  >
    <div class="user-profile mt-4 md:mt-24 grid-2">
      <div class="grid-item">
        <div
          class="upload-box-container border inline-block border-gray-200 p-3 relative"
        >
          <div class="yellow upload-box">
            <div
              class="box-content h-full flex justify-center items-center text-color"
            >
              <div class="profile-image relative" v-if="values.profile_picture">
                <img
                  class="upload-box object-contain"
                  ref="profileImage"
                  :src="values.profile_picture.url"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            class="label bg-nColorMustard text-white absolute top-0 right-0 md:top-20 md:-right-10 text-2xl p-2"
          >
            <p>Hi there! I'm</p>
          </div>
        </div>
      </div>

      <div class="user-name p-3">
        <p
          class="title font-Fancy yellow-text md:mb-20"
          v-if="values.user_name"
        >
          {{ values.user_name }}
        </p>

        <div v-if="values.quote != null && values.quote != 'null'">
          <div class="text-2xl text-nColorMustard inline">“</div>
          <p
            class="text-md text-gray-600 font-semibold text-center px-2 inline italic"
          >
            {{ values.quote }}
          </p>
          <div class="text-2xl text-nColorMustard inline">”</div>
        </div>
      </div>
    </div>

    <div
      class="user-work-details grid-2 md:mt-24"
      v-if="
        values.birthday ||
        values.personal.designation ||
        values.personal.based_out_of ||
        values.personal.years_of_exp ||
        values.personal.last_job
      "
    >
      <CircleContainer color="yellow" class="">
        <div class="dob mb-2 md:mb-6" v-if="values.birthday">
          <p>
            Born on
            <span
              class="inline-block w-12 text-center mb-2 font-Fancy text-xl md:text-3xl font-bold yellow-text"
            >
              {{ values.birthday.date }}
            </span>
          </p>
          <p>
            of
            <span
              class="inline-block text-center font-Fancy text-xl md:text-3xl font-bold yellow-text"
            >
              {{ values.birthday.month }},
              {{ values.birthday.year }}
            </span>
          </p>
        </div>

        <div class="pos mb-2 md:mb-6">
          <p class="mb-2" v-if="values.personal.designation">
            I am a
            <span
              class="inline-block mx-2 text-center font-Fancy text-xl md:text-3xl font-bold yellow-text"
            >
              {{ values.personal.designation }}
            </span>
          </p>
          <p v-if="values.personal.based_out_of">
            based out of
            <span
              class="inline-block mx-2 text-center font-Fancy text-xl md:text-3xl font-bold yellow-text"
            >
              {{ values.personal.based_out_of }}
            </span>
          </p>
        </div>

        <div class="exp mb-2 md:mb-6" v-if="values.personal.years_of_exp">
          <p>
            I have
            <span
              class="inline-block mx-2 text-center font-Fancy text-xl md:text-3xl font-bold yellow-text"
            >
              {{ values.personal.years_of_exp }}
            </span>
            years
          </p>
          <p>of experience and</p>
        </div>

        <div class="prev-company" v-if="values.personal.last_job">
          <p>my last sting was at</p>
          <p>
            <span
              class="inline-block w-56 text-center font-Fancy text-xl md:text-3xl font-bold yellow-text"
            >
              {{ values.personal.last_job }}
            </span>
          </p>
        </div>
      </CircleContainer>
      <div
        class="user-name p-3 flex flex-col justify-between items-center mt-0"
      >
        <img
          class="w-72 illustration hidden md:block"
          :src="require('@/assets/images/nha/stairs.svg')"
          alt=""
        />
        <span
          class="title font-Fancy green-text"
          v-if="
            values.personal.family_info || values.personal.social_media_link
          "
          >Personal</span
        >
      </div>
    </div>

    <div
      class="personal-gif grid-2 mt-0 md:mt-8"
      v-if="values.personal.family_info || values.personal.social_media_link"
    >
      <div class="personal-illu flex items-center">
        <img
          class="w-96 mx-auto illustration hidden md:block"
          :src="require('@/assets/images/nha/family.svg')"
          alt=""
        />
      </div>

      <div class="grid-item flex justify-center">
        <div v-if="values.personal.animation" class="relative">
          <img
            class="upload-box object-contain border p-3"
            :src="values.personal.animation"
            alt=""
          />
        </div>
      </div>
    </div>

    <div
      class="personal-details grid-2 mt-8 md:mt-24"
      v-if="values.personal.family_info || values.personal.social_media_link"
    >
      <CircleContainer color="green">
        <div class="mb-6">
          <p class="mb-4">My family consists of</p>
          <p>
            <span
              class="inline-block text-center font-Fancy text-3xl font-semibold green-text mx-5"
            >
              {{ values.personal.family_info }}
            </span>
          </p>
        </div>
      </CircleContainer>
      <div
        class="personal-illu flex flex-col justify-evenly text-white font-Fancy items-center"
      >
        <img
          class="w-60 illustration hidden md:block"
          :src="require('@/assets/images/nha/phone.svg')"
          alt=""
        />

        <div class="rounded-input w-full rounded-full place-items-center">
          <p
            class="social-link solid-green md:w-full px-5 pb-1 pt-4 text-center rounded-full text-2xl md:text-4xl placeholder-gray-200 text-white"
          >
            {{ values.personal.social_media_link }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="interests grid-2 mt-24"
      v-if="values.interests.wanted_to_be || values.interests.learned_last_year"
    >
      <div class="grid-item flex justify-center">
        <img
          v-if="values.interests.animation"
          class="upload-box object-contain border p-3"
          :src="values.interests.animation"
          alt=""
        />
      </div>
      <div class="interest-header flex flex-col justify-end">
        <span class="title font-Fancy pink-text">Interests</span>
      </div>
    </div>

    <div
      class="aspire mt-2 md:mt-24 grid-2"
      v-if="values.interests.wanted_to_be || values.interests.learned_last_year"
    >
      <CircleContainer color="pink" class="order-2 md:order-1">
        <div class="asp mb-10" v-if="values.interests.wanted_to_be">
          <p>When I was little</p>
          <p>I wanted to be a</p>
          <p>
            <span
              class="inline-block text-center font-Fancy text-3xl font-bold pink-text"
            >
              {{ values.interests.wanted_to_be || "Cricketer" }}
            </span>
          </p>
        </div>

        <div class="learn mb-10" v-if="values.interests.learned_last_year">
          <p>In the last year</p>
          <p>I learned to</p>
          <p>
            <span
              class="inline-block text-center font-Fancy text-3xl font-bold pink-text"
            >
              {{ values.interests.learned_last_year || "How to dance" }}
            </span>
          </p>
        </div>
      </CircleContainer>

      <div
        class="link-illu font-Fancy flex flex-col justify-between order-1 md:order-2"
      >
        <img
          class="w-72 illustration hidden md:block"
          :src="require('@/assets/images/nha/children.svg')"
          alt=""
        />
      </div>
    </div>

    <div
      class="favorites mt-24 grid-2"
      v-if="
        values.favorites.book ||
        values.favorites.tv_show ||
        values.favorites.food ||
        values.favorites.cheer_for ||
        values.favorites.song
      "
    >
      <div class="grid-item flex justify-center">
        <img
          v-if="values.favorites.animation"
          class="upload-box object-contain border p-3"
          :src="values.favorites.animation"
          alt=""
        />
      </div>

      <div class="interest-header flex flex-col justify-end">
        <span class="title font-Fancy purple-text">Favorites</span>
      </div>
    </div>

    <div
      class="favorites-form grid-2 mt-2 md:mt-24"
      v-if="
        values.favorites.book ||
        values.favorites.tv_show ||
        values.favorites.food ||
        values.favorites.cheer_for ||
        values.favorites.song
      "
    >
      <div class="image-container flex flex-col justify-center items-center">
        <img
          class="w-36 md:w-48 illustration hidden md:block"
          :src="require('@/assets/images/nha/working.svg')"
          alt=""
        />
      </div>

      <CircleContainer color="purple">
        <div class="book mb-2 md:mb-6 m-5" v-if="values.favorites.book">
          <p>Book I love</p>
          <p>
            <span
              class="inline-block text-center font-Fancy text-xl md:text-2xl font-bold purple-text"
            >
              {{ values.favorites.book }}
            </span>
          </p>
        </div>

        <div class="binge-tv mb-2 md:mb-6" v-if="values.favorites.tv_show">
          <p>My comfort binge TV show</p>
          <p>
            <span
              class="inline-block text-center font-Fancy text-xl md:text-2xl font-bold purple-text"
            >
              {{ values.favorites.tv_show }}
            </span>
          </p>
        </div>

        <div class="food mb-2 md:mb-6" v-if="values.favorites.food">
          <p>My favorite kinda food</p>
          <p>
            <span
              class="inline-block text-center font-Fancy text-xl md:text-2xl font-bold purple-text"
            >
              {{ values.favorites.food }}
            </span>
          </p>
        </div>

        <div class="binge-tv mb-2 md:mb-6" v-if="values.favorites.cheer_for">
          <p>I cheer for</p>
          <p>
            <span
              class="inline-block text-center font-Fancy text-xl md:text-2xl font-bold purple-text"
            >
              {{ values.favorites.cheer_for }}
            </span>
          </p>
        </div>

        <div class="binge-tv mb-2 md:mb-6" v-if="values.favorites.song">
          <p>Listening on Loop</p>
          <p>
            <span
              class="inline-block text-center font-Fancy text-xl md:text-2xl font-bold purple-text"
            >
              {{ values.favorites.song }}
            </span>
          </p>
        </div>
      </CircleContainer>
    </div>

    <div
      class="skills mt-10 md:mt-24 grid-2 lg:flex lg:justify-around md:flex-wrap lg:flex-nowrap items-baseline"
      v-if="
        values.skills.unbeatable_at ||
        values.skills.mad_skills_in ||
        values.skills.good_at_playing ||
        values.skills.languages
      "
    >
      <div class="grid-item flex justify-center">
        <img
          v-if="values.skills.animation"
          class="upload-box object-contain border p-3"
          :src="values.skills.animation"
          alt=""
        />
      </div>

      <div class="skill-header">
        <span class="title font-Fancy yellow-text">Skills</span>
      </div>
    </div>

    <div
      class="illu justify-end my-5 hidden md:flex"
      v-if="
        values.skills.unbeatable_at ||
        values.skills.mad_skills_in ||
        values.skills.good_at_playing ||
        values.skills.languages
      "
    >
      <img
        class="w-72 illustration hidden md:block"
        :src="require('@/assets/images/nha/pen.svg')"
        alt=""
      />
    </div>

    <div
      class="skill-content md:mt-24 flex justify-evenly flex-wrap lg:flex-nowrap"
      v-if="
        values.skills.unbeatable_at ||
        values.skills.mad_skills_in ||
        values.skills.good_at_playing ||
        values.skills.languages
      "
    >
      <img
        class="w-48 self-start illustration hidden md:block"
        :src="require('@/assets/images/nha/guitar.svg')"
        alt=""
      />

      <CircleContainer color="yellow">
        <div
          class="mb-2 mt-2 md:mt-0 md:mb-6"
          v-if="values.skills.unbeatable_at"
        >
          <p>No one can beat me at</p>
          <span
            class="inline-block text-center font-Fancy text-3xl font-bold yellow-text"
          >
            {{ values.skills.unbeatable_at || "Any video game ever" }}
          </span>
        </div>

        <div class="mb-2 md:mb-6" v-if="values.skills.mad_skills_in">
          <p class="mb-2">I also have mad skills in</p>
          <span
            class="inline-block text-center font-Fancy text-3xl font-bold yellow-text"
          >
            {{ values.skills.mad_skills_in || "Table Tennis" }}
          </span>
        </div>

        <div class="mb-2 md:mb-6" v-if="values.skills.good_at_playing">
          <p>I am good at playing</p>

          <span
            class="inline-block text-center font-Fancy text-3xl font-bold yellow-text"
          >
            {{ values.skills.good_at_playing || "the Piano" }}
          </span>
        </div>

        <div class="speak" v-if="values.skills.languages">
          <p>and speaking</p>
          <p>
            <span
              class="inline-block text-center font-Fancy text-3xl font-bold yellow-text"
            >
              {{ values.skills.languages || "Kannada, Tamil and Hindi" }}
            </span>
          </p>
        </div>
      </CircleContainer>

      <img
        class="w-60 lg:w-72 self-end illustration hidden md:block"
        :src="require('@/assets/images/nha/running.svg')"
        alt=""
      />
    </div>

    <div
      class="inspiration-gif text-center md:text-left mt-8 md:mt-24"
      v-if="
        values.inspiration.all_time_hero ||
        values.inspiration.cant_live_without_tech ||
        values.inspiration.friends_headlines_at
      "
    >
      <div class="grid-item flex justify-center">
        <img
          v-if="values.inspiration.animation"
          class="upload-box object-contain border p-3"
          :src="values.inspiration.animation"
          alt=""
        />
      </div>
    </div>

    <div
      class="inspiration-header mt-10 md:mt-24 text-center"
      v-if="
        values.inspiration.all_time_hero ||
        values.inspiration.cant_live_without_tech ||
        values.inspiration.friends_headlines_at
      "
    >
      <span class="title font-Fancy green-text">Inspiration</span>
    </div>

    <div
      class="inspiration-content my-2 md:my-24 flex justify-evenly flex-wrap lg:flex-nowrap"
      v-if="
        values.inspiration.all_time_hero ||
        values.inspiration.cant_live_without_tech ||
        values.inspiration.friends_headlines_at
      "
    >
      <img
        class="w-60 hidden md:block self-end illustration"
        :src="require('@/assets/images/nha/superman.svg')"
        alt=""
      />
      <CircleContainer color="green">
        <div class="mb-6" v-if="values.inspiration.all_time_hero">
          <p>My all time hero is</p>
          <p>
            <span
              class="inline-block text-center font-Fancy text-3xl font-bold green-text"
            >
              {{ values.inspiration.all_time_hero || "Spiderman" }}
            </span>
          </p>
        </div>

        <div class="mb-6" v-if="values.inspiration.cant_live_without_tech">
          <p class="mb-2">The tech invention I can't live without is</p>
          <p>
            <span
              class="inline-block text-center font-Fancy text-3xl font-bold green-text"
            >
              {{ values.inspiration.cant_live_without_tech }}
            </span>
          </p>
        </div>

        <div class="mb-6" v-if="values.inspiration.friends_headlines_at">
          <p>I will be making friends and headlines at</p>
          <p>
            <span
              class="inline-block text-center font-Fancy text-3xl font-bold green-text"
            >
              {{ values.inspiration.friends_headlines_at }}
            </span>
          </p>
        </div>
      </CircleContainer>
      <img
        class="hidden md:block w-24 self-start illustration"
        :src="require('@/assets/images/nha/bulb.svg')"
        alt=""
      />
    </div>
    <div class="bg-white p-10"></div>
  </div>
</template>

<script>
import CircleContainer from "@/components/Demo/CatalogElements/CircleContainer.vue";

export default {
  name: "ProfileComponent",
  props: {
    values: {
      type: Object,
      required: true,
    },
  },
  components: { CircleContainer },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.obk-nha {
  background-repeat: no-repeat;
}

.bg-image {
  z-index: -1;
}

.grid-2 {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
}

.title {
  font-size: 5rem;
  line-height: 120px;
  background-color: transparent;
}

.green {
  background-color: #e4f7f4;
}

.yellow {
  background-color: #fff8de;
}

.purple {
  background-color: #e9ecff;
}
.pink {
  background-color: #fff0f5;
}

.solid-green {
  background-color: #11bca2;
}

.solid-pink {
  background-color: #ff5670;
}

.purple-btn {
  color: #7789e0;
}

.yellow-btn {
  color: #d8c27d;
}

.yellow-btn-bg {
  background-color: #d8c27d;
}

.pink-btn {
  color: #d89cae;
}

.yellow-text {
  color: #fdb400;
}

.green-text {
  color: #11bca2;
}

.pink-text {
  color: #ff5670;
}

.purple-text {
  color: #3d50e0;
}

.green-btn {
  color: #85c6ba;
}

.gray {
  background-color: rgba(245, 243, 243, 0.856);
}

.text-color {
  color: #777777;
}

.upload-box {
  width: 300px;
  height: 240px;
}

.round-box {
  width: 450px;
  height: 450px;
}

.social-link {
  box-shadow: 10px 10px 30px #11bca278;
}

.fav-link {
  box-shadow: 10px 10px 30px #ff566f8e;
}

input {
  transition: all 500ms ease-in-out;
}

.yellow-field:focus {
  outline: #fdb400 1px solid !important;
}

.green-field:focus {
  outline: #11bca2 1px solid !important;
}

.pink-field:focus {
  outline: #ff5670 1px solid !important;
}

.purple-field:focus {
  outline: #7789e0 1px solid !important;
}

.purple-field::placeholder,
.pink-field::placeholder,
.yellow-field::placeholder,
.green-field::placeholder {
  color: #1f1d1d41;
}

.illustration {
  background-color: white;
}

input[type="file"] {
  display: none;
}

@media (min-width: 768px) {
  .grid-2 {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }

  .title {
    background-color: white;
  }
}

@media (min-width: 1024px) {
  .obk-nha {
    padding: 0 2rem;
    background-image: url("~@/assets/images/nha/dottedBG.svg");
    background-size: cover;
    background-position: center;
  }
  .grid-2 {
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
  .title {
    font-size: 7rem;
    line-height: 120px;
  }
  .upload-box {
    width: 300px;
    height: 240px;
  }
}

@media (min-width: 1280px) {
  .obk-nha {
    padding: 0 10rem;
  }

  .title {
    font-size: 8rem;
    line-height: 140px;
  }
  .upload-box {
    width: 360px;
    height: 270px;
  }
}

@media (min-width: 1400px) {
  .title {
    font-size: 9rem;
    line-height: 160px;
  }

  .upload-box {
    width: 400px;
    height: 270px;
  }
}

@media (min-width: 1600px) {
  .title {
    font-size: 12rem;
    line-height: 220px;
  }

  .upload-box {
    width: 450px;
    height: 300px;
  }
}
</style>
